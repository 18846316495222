import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { Box, Container, Flex, Grid, Image } from "theme-ui"
import calendar from "../images/calendar.svg"
import pin from "../images/pin.svg"
import EventCta from "./eventCta"

const EventHero = ({ page }) => (
  <Box
    sx={{
      position: "relative",
      mb: 4,
      backgroundColor: page.primaryColor?.hex || "primary",
    }}
  >
    <GatsbyImage image={page.heroImage.gatsbyImageData} />
    <Box
      sx={{
        backgroundColor: page.primaryColor?.hex || "primary",
        paddingTop: [0],
      }}
    >
      <Container>
        <Flex
          sx={{
            justifyContent: "space-between",
            flexDirection: ["column", "column", "column", "row"],
            flexWrap: ["wrap", "nowrap"],
            gap: 3,
            p: {
              color: "light",
              fontSize: [2],
              fontWeight: 500,
              m: [0],
              mt: ["3px"],
            },
            img: {
              minWidth: "32px",
              mr: [2, 3],
            },
          }}
        >
          <Flex sx={{ alignItems: "center", flex: ["none"] }}>
            <Image src={calendar} />
            <Box dangerouslySetInnerHTML={{ __html: page.date }} />
          </Flex>
          <Flex sx={{ alignItems: "center", flex: ["1 1 45%", "0 1 auto"] }}>
            <Image src={pin} />
            <Box dangerouslySetInnerHTML={{ __html: page.location }} />
          </Flex>
          <EventCta
            registrationCta={page.registrationCta}
            registrationLink={page.registrationLink}
            primaryColor={page.primaryColor}
            variant="light"
          />
        </Flex>
      </Container>
    </Box>
  </Box>
)

export default EventHero
